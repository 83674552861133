import React, { useState, useEffect } from "react";
// import Header from "../components/Header";
import SocialMedia from "../components/SocialMedia";
import "semantic-ui-css/semantic.min.css";
import styles from "../styles/Slug.module.css";
import {
  getSubCategoryDetail,
  getPagesContentBySubCategory,
  getPagesContent,
  getPagesList,
} from "../api/pageDetailsApi";
import { useRouter } from "next/router";
import Head from "next/head";
import ReleatedContentCard from "../components/ReleatedContentCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { Dropdown } from "semantic-ui-react";

import Bredcrum from "../components/Bredcrum";
import { SetRichTextContent, clearSlug, formatAudio, toTitleCase } from "../utils/Helper";
import { useDispatch } from "react-redux";
import { audioInfoChange, audioStateChange, updateIndex, updateSongs } from "../actions/audioSlice";

const fetchSubCategoryData = async (uri) => {
  const res = await getSubCategoryDetail(uri);
  // console.log("resData",res.data)
  if (res.data.length > 0) {
    return res.data[0];
  }
  throw `error,fetchSubCategoryData,${uri}`;
};

const fetchSubCategoryPagesData = async (uri) => {
  const res = await getPagesContentBySubCategory(uri);
  // console.log("res",res)
  return res.data;
};

const fetchPageData = async (uri) => {
  const res = await getPagesContent(uri);
  if (res.data.length > 0) {
    return res.data[0];
  }
  throw `error,fetchPageData,${uri}`;
};

const fetchRelatedContent = async (keywords, pageUrl) => {
  if(!keywords) return []
  let mainKeywords = keywords.slice(0, 5);
  let filter = ``;
  mainKeywords?.map((item, index) => {
    filter += `filters[$or][${index}][seo][metaKeywords][$contains]=${item.trim()}&`;
  });
  if (pageUrl) {
    filter += `filters[Page_url][$ne]=${pageUrl.trim()}&`;
  }
  let pageNation = `pagination[page]=1&pagination[pageSize]=5&fields[0]=Page_title&fields[1]=Page_url`;
  let final = filter + pageNation;
  console.log("finalRelatedURl is",final);
  const pages = await getPagesList(final);
  console.log("pagesDatas",pages);
  return pages.data;
};

export async function getServerSideProps(ctx) {
  const { params } = ctx;
  const slug = clearSlug(params.slug);
  const propsData = {
    subCategory: null,
    page: null,
    subCategoryPages: null,
    relatedContent: [],
  };

  try {
    // remap and move out the pdf urls
    let pdfSlug = slug.join("");
    console.log("🚀 ~ file: [...slug].jsx:77 ~ getServerSideProps ~ pdfSlug:", pdfSlug)
    if (pdfSlug.includes(".pdf")) {
      const uri = `https://shaivamfiles.fra1.cdn.digitaloceanspaces.com/${slug.join("/")}`
    propsData.slug = slug;
    console.log(slug.join("/"));
    propsData.page = { 
      attributes: {
        description:`<a href="${uri}"> ${pdfSlug} </a>`,
        Page_title:toTitleCase(pdfSlug.replaceAll("-"," ").replace(".pdf",""))
      }
    }
    return {
      props: propsData,
    };
    }

    if (slug && slug.length == 2) {
      // promiseAll
      const promises = [fetchSubCategoryData(slug[0]),fetchPageData(slug[1])]
      const [sub, subPage] = await Promise.allSettled(promises)

      propsData.subCategory = sub?.value || null
      propsData.page = subPage?.value || null
      // Promise All Ends ..

      propsData.relatedContent = await fetchRelatedContent(
        propsData.page?.attributes?.seo?.metaKeywords.split(","),
        propsData.page?.attributes?.Page_url
      );
    }
    
    if (slug && slug.length == 1) {
      // promiseAll
      const promises = [fetchSubCategoryData(slug[0]),fetchSubCategoryPagesData(slug[0])]
      const [sub,subPage] = await Promise.allSettled(promises)
      propsData.subCategory = sub?.value || null
      propsData.subCategoryPages = subPage?.value || null

      propsData.relatedContent = await fetchRelatedContent(
        propsData.subCategory?.attributes?.seo?.metaKeywords.split(",")
      );
    }

    propsData.slug = slug;
    return {
      props: propsData,
    };
  } catch (err) {
    console.log(err);
    console.log(`content not found in strapi, ${err}`);
    return {
      notFound: true,
    };
  }
}

const OptionalPages = ({
  slug,
  subCategory,
  subCategoryPages,
  page,
  relatedContent,
}) => {
  const AudioOptions = [];
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setData([]);
    setHasMore(true);
  }, [slug[0]]);

  const tempList = formatAudio(page?.attributes?.Audio_url)
  if (tempList?.length > 0) {
    let content = tempList?.map((item,i) => {
      return {
        key: i,
        text: item?.attributes?.title,
        value: item?.attributes?.rawURL,
      };
    });
    AudioOptions.push(...content);
  }

  const handlePlay = (_,{value}) => {
    
    
    const index = AudioOptions?.findIndex((sr, i) => sr.value === value);
    
    
    dispatch(
      updateSongs({
        songs: tempList,
      })
    );
    dispatch(
      audioInfoChange({
        songIndex: index === -1 ? 0 : index,
        album: "",
      })
    );

    dispatch(updateIndex(index === -1 ? 0 : index));
    
    dispatch(audioStateChange(true));
  };


  return (
    <>
      <Head>
        <title>
          {page
            ? page?.attributes?.Page_title
            : subCategory?.attributes?.Sub_category_Title}
        </title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta
          name="description"
          content={
            page
              ? page?.attributes?.seo?.metaDescription
              : subCategory?.attributes?.seo?.metaDescription
          }
        />
        <meta
          name="keywords"
          content={
            page
              ? page?.attributes?.seo?.metaKeyword
              : subCategory?.attributes?.seo?.metaKeyword
          }
        />
        <meta
          property="og:title"
          content={
            page
              ? page?.attributes?.seo?.metaTitle
              : subCategory?.attributes?.seo?.metaTitle
          }
          key="og:title"
        />
        <meta
          property="og:description"
          content={
            page
              ? page?.attributes?.seo?.metaDescription
              : subCategory?.attributes?.seo?.metaDescription
          }
          key="og:description"
        />
        <link rel="icon" href="/assests/icons/logo.png" />
      </Head>
      {/* <Header /> */}
      <div className={styles.hinduWrapper}>
        <div className={styles.social}>
          <div className={styles.path}>
            {slug && slug[1] == undefined && slug[0] && (
              <Bredcrum slug={slug} />
            )}
            {slug && slug[0] && slug[1] && <Bredcrum slug={slug} />}
          </div>
          <div className={styles.socailMediaShare}>
            <SocialMedia />
          </div>
          {page && page?.attributes?.Audio_url ? <div className={styles.socailMediaShare}>
        <Dropdown
        clearable
        selection
        placeholder="Please select a Audio"
        options={AudioOptions}
        onChange={handlePlay}
        />
        </div>: null}
        </div>
        <div className={styles.HinduTitle}>
          <img src="/assests/icons/om.png" alt="" />
          <h1>
            {subCategory && !page
              ? subCategory?.attributes?.Sub_category_Title
              : null}
            {page ? page?.attributes?.Page_title : null}
          </h1>
          <img src="/assests/icons/om.png" alt="" />
          <p></p>
        </div>
        {subCategory && !page
          ? SetRichTextContent(subCategory?.attributes?.description)
          : null}
        {subCategory?.attributes.autoGenerated=="Yes" && subCategoryPages
          ? SubCategoryPagesContent(
              subCategoryPages,
              slug[0],
              data,
              setData,
              hasMore,
              setHasMore
            )
          : null}
        {page ? SetRichTextContent(page?.attributes?.description) : null}
        {RelatedContent(relatedContent)}
      </div>
    </>
  );
};

function SubCategoryPagesContent(
  list,
  uri,
  data,
  setData,
  hasMore,
  setHasMore
) {
  const getMorePost = async () => {
    let pageNo = (list.length + data.length) / 10 + 1;
    console.log("pageNo", pageNo);
    const res = await getPagesContentBySubCategory(uri, pageNo);
    if (res?.data?.length > 0) {
      setData([...data, ...res.data]);
      if (res.data.length < 10) {
        setHasMore(false);
      }
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  };
  const router = useRouter();
  return (
    <div className={styles.hinduContent}>
      <InfiniteScroll
        dataLength={list.length + data.length}
        next={getMorePost}
        hasMore={hasMore}
        loader={<h3> Loading...</h3>}
        scrollThreshold={0.5}
        endMessage={""}
      >
        {list.map(({ attributes, id }) => (
          <div key={id}>
            <h3 onClick={() => router.push(`/${uri}/${attributes?.Page_url}`)}>
              {attributes?.Page_title}
            </h3>

            <hr />
          </div>
        ))}
        {data.map(({ attributes, id }) => (
          <div key={id}>
            <h3 onClick={() => router.push(`/${uri}/${attributes?.Page_url}`)}>
              {attributes?.Page_title}
            </h3>

            <hr />
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
}

function RelatedContent(content) {
  return (
    <>
      <h2 className={styles.releatedHeading}> Related Content </h2>
      <div className={styles.reletedCardWrapper}>
        <div className={styles.reletedCard}>
          {/* <Card /> */}
          {content?.map((item, index) => {
            let url =
              "/" +
              item?.attributes?.sub_category.data?.attributes
                ?.Sub_category_URL +
              "/" +
              item?.attributes?.Page_url;
            return (
              <ReleatedContentCard
                key={index}
                title={item?.attributes.Page_title}
                uri={url}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default OptionalPages;
