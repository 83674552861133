import React, { useEffect } from "react";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styles from "../styles/SocialMedia.module.css";
import socialMessage from "../data/social.json";
import { useSelector } from "react-redux";


import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "next-share";
import { IoLogoWhatsapp } from "react-icons/io";

const SocialMedia = () => {
  const globalselectedlang = useSelector((state) => state.language.globalLang);

  const shareText =
    socialMessage[globalselectedlang ? globalselectedlang : "en"].share;

  const [path, setPath] = useState("");
  const [copy, setCopy] = useState(false);

  useEffect(() => {
    setPath(window.location.href);
  }, []);

  return (
    <div>
      <div className={styles.links}>
        <span1>
          {/* <img src="/assests/icons/sharebold.svg" alt="" /> */}
          Share this page
        </span1>
        <TwitterShareButton
          url={path}
          title={shareText}
          separator="::"
          hashtag={"#shaivam"}
        >
          <img src="/assests/icons/twitter.svg" alt="" />
        </TwitterShareButton>
        <FacebookShareButton
          url={"https://shaivam.org"}
          text={'next-share is a social share buttons for your next React apps.'}
          quote={'next-share is a social share buttons for your next React apps.'}
          hashtag={"#shaivam"}
        >
          <img src="/assests/icons/fb.svg" alt="" />
        </FacebookShareButton>
        <WhatsappShareButton
          url={path}
          title={shareText}
          separator="::"
          hashtag={"#shaivam"}
          style={{ marginRight: "10px" }}
        >
          <IoLogoWhatsapp fontSize={26} style={{ color: "#C45D4E" }} />
        </WhatsappShareButton>

        <span>|</span>
        <CopyToClipboard
          className={styles.share}
          onCopy={() => setCopy(true)}
          text={path}
        >
          <span>
            <img src="/assests/icons/link.svg" alt="" />{" "}
            {copy ? "link copied" : "Copy link"}
          </span>
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default SocialMedia;
