import { useRouter } from 'next/router'
import React from 'react'
import { useState } from 'react'
import styles from '../styles/BredCrum.module.css'
import { useEffect } from 'react'

const Bredcrum = ({slug = [],additionalInfo=""}) => {
  const router = useRouter();

  const [path, setpath] = useState(router.asPath.slice(1, -1));
  const [splitPath, setsplitPath] = useState(path?.split('/')?.filter(i => i !== "#gsc.tab=")?.slice(0, 2));



  const pathHandle = ({ item, index }) => {
    if (index == 0 && splitPath.length == 1) {
      return;
    }
    if (index == 2 && splitPath.length == 3) {
      return;
    }
    if (index == 1 && splitPath.length == 2) {
      return;
    }
    if(additionalInfo !=="" && splitPath.length == 2){
      router.push(`${additionalInfo}/${item}`)
      return;
    }
    router.push(`/${item}`)


  }

  useEffect(()=>{
    if(slug.length > 0){
      setsplitPath(slug)
      
    }
  },[slug])
 
  function capitalizeFirstLetter(string) {
    if(!string) return
    return string.charAt(0).toUpperCase() + string.slice(1);
}

  return (
    <div className={styles.Bredcrum}  >
      <p style={{ fontFamily: 'Lora', fontWeight: 'bold' }} onClick={() => router.push('/')} >Home &gt; </p>
      {
        additionalInfo !== "" && (
          <p style={{ fontFamily: 'Lora', fontWeight: 'bold' }} onClick={() => router.push(`${additionalInfo}`)} >{capitalizeFirstLetter(`${additionalInfo?.split('/')[1]}`)} &gt; </p>
        )
      }
      {
        splitPath?.map((item, index) => {
          return <div key={item} className={styles.h2} >


            <h2 style={{ color: (index == 0 && splitPath.length == 1 || index == 1 && splitPath.length == 2) ? 'rgba(0, 0, 0, 0.275)' : 'black' }} onClick={() => pathHandle({ item, index })}

            > {item} {index === (splitPath.length - 1) ? '' : '>'}  </h2>
            
          </div>
        })
      }

    </div>
  )
}

export default Bredcrum;
