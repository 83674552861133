import React from 'react'
import styles from "../styles/ReleatedContentCard.module.css";
import { useRouter } from "next/router";

const ReleatedContentCard = (props) => {
    const router = useRouter();

  return (
      <div className={styles.articles__list__item}>
          {/* <div className={styles.artTitle}>
              <span>{props.upperTitle}</span>
          </div> */}

          <div className={styles.content}>
              <h1> {props.title.slice(0,60)} </h1>
              {/* <p>{props.date}</p> */}
          </div>

          <div className={styles.btn}>
              <button 
                  className={styles.readmore}
                  style={{cursor: 'pointer'}} onClick={() => router.push(props.uri)}
              >
                  Read more <img src="/assests/icons/right.png" alt="" />
              </button>
              {/* <span>
                  {props.pdf ? <img src="/assests/icons/pdf.svg" alt="" /> : ""}
                  {props.pdf}
              </span> */}
          </div>
      </div>
  )
}

export default ReleatedContentCard