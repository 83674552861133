import { Config } from "../utils/config";

export const getPagesContentBySubCategory = async (uri, pageNo) => {
  return fetch(
    `${
      Config.BaseUrl
    }/pages?filters[sub_category][Sub_category_URL][$eq]=${uri}&sort=page_order:ASC&pagination[page]=${
      pageNo ? pageNo : 1
    }&pagination[pageSize]=10&fields[0]=Page_title&fields[1]=Page_url`
  ).then((res) => res.json());
};

export const getPagesContent = async (url) => {
  const uri = `${Config.BaseUrl}/pages?filters[Page_url][$eq]=${url}&populate[seo][populate]=*`;
  console.log("URI ==>", uri);
  return fetch(uri).then((res) => res.json());
};

export const getPagesList = async (filter) => {
  const uri = `${Config.BaseUrl}/pages?${filter}&populate[sub_category][fields][0]=Sub_category_URL`;
  console.log("uri is =>", uri);
  return fetch(uri).then((res) => res.json());
};

export const getSubCategoryDetail = async (url) => {
  const uri = `${Config.BaseUrl}/sub-categories?filters[Sub_category_URL][$eq]=${url}&populate[seo][populate]=*&fields[0]=Sub_category_Title&fields[1]=Sub_category_URL&fields[2]=description&fields[3]=autoGenerated`;
  return fetch(uri).then((res) => res.json());
};

export const getSubCategories = async ({ queryKey }) => {
  const { pageParam, text, filter } = queryKey[1];
  const uri = `${
    Config.BaseUrl
  }/sub-categories?filters[Sub_category_Title][$contains]=${text}&fields[0]=Sub_category_Title&fields[1]=Sub_category_URL&pagination[page]=${pageParam}&pagination[pageSize]=10&${
    filter?.split(":")[0] !== "" ? `sort=${filter}` : ""
  }`;
  return fetch(uri).then((res) => res.json());
};

export const getPages = async ({ queryKey }) => {
  const { pageParam, text, filter } = queryKey[1];
  const uri = `${
    Config.BaseUrl
  }/pages?filters[Page_title][$contains]=${text}&fields[0]=Page_title&fields[1]=Page_url&populate[sub_category]=Sub_category_URL&pagination[page]=${pageParam}&pagination[pageSize]=10&${
    filter?.split(":")[0] !== "" ? `sort=${filter}` : ""
  }`;
  return fetch(uri).then((res) => res.json());
};

export const getLanguage = async ({ queryKey }) => {
  const { pageParam, text, filter } = queryKey[1];
  const uri = `${
    Config.BaseUrl
  }/languages?filters[name][$contains]=${text}&fields[0]=name&pagination[page]=${pageParam}&pagination[pageSize]=10&${
    filter?.split(":")[0] !== "" ? `sort=${filter}` : ""
  }`;
  return fetch(uri).then((res) => res.json());
};

export const getTemples = async ({ queryKey }) => {
  const { pageParam, text, filter } = queryKey[1];
  const uri = `${
    Config.BaseUrl
  }/temples?filters[Name_of_the_place][$contains]=${text}&fields[0]=Name_of_the_place&fields[1]=Temples_Url&pagination[page]=${pageParam}&pagination[pageSize]=10&${
    filter?.split(":")[0] !== "" ? `sort=${filter}` : ""
  }`;
  return fetch(uri).then((res) => res.json());
};

export const getScripure = async ({ queryKey }) => {
  const { pageParam, text, filter } = queryKey[1];
  const uri = `${
    Config.BaseUrl
  }/scriptures?filters[Scripture_title][$contains]=${text}&fields[0]=Scripture_title&fields[1]=Scripture_url&populate[language]=name&pagination[page]=${pageParam}&pagination[pageSize]=10&${
    filter?.split(":")[0] !== "" ? `sort=${filter}` : ""
  }`;
  return fetch(uri).then((res) => res.json());
};

export const getThirumurai = async ({ queryKey }) => {
  const { pageParam, text, filter } = queryKey[1];
  const uri = `${
    Config.BaseUrl
  }/thirumurais?filters[Thirumurai_title][$contains]=${text}&fields[0]=Thirumurai_title&fields[1]=Thirumurai_url&populate[trimurai_category]=url&pagination[page]=${pageParam}&pagination[pageSize]=10&${
    filter?.split(":")[0] !== "" ? `sort=${filter}` : ""
  }`;
  return fetch(uri).then((res) => res.json());
};
